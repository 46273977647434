import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import next from '../assets/img/next.png';
import prev from '../assets/img/prev.png';
import star from '../assets/img/star.png';
import starN from '../assets/img/starN.png';
import man3 from '../assets/img/man3.jpg';
import female1 from '../assets/img/female1.jpg';
import man2 from '../assets/img/man2.jpg';
import dumika from '../assets/img/dumika.jpg';
import gril from '../assets/img/girl.png';
import boy from '../assets/img/boy.png';
import prem from '../assets/img/Prem.png';
import dumm from '../assets/img/Dumalka Kariyawasam.png';

const SlideShow = () => {
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: null, // Disable default arrows
    prevArrow: null, // Disable default arrows
  };

  return (
    <div className="slide-container">
      <div className="row">
        <div className="col-12">
          <div className="row align-items-center mb-4">
            <div className="col-md-8 col-12" style={{ padding: '0' }}>
              <div className="d-flex flex-column h-100 justify-content-center">
                <h4 className="slider_h">What people say about us</h4>
                <p className="slider_p mb-0">
                  Don’t take our word for it. Trust our customers
                </p>
              </div>
            </div>
            <div className="col-md-4 col-12" style={{ padding: '0' }}>
              <div
                className="d-flex h-100 justify-content-center align-items-center"
                style={{ float: 'right' }}
              >
                <div className="custom-arrows mb-5">
                  <button
                    onClick={() => sliderRef.current.slickPrev()}
                    className="custom-prev-arrow"
                  >
                    <img
                      style={{ marginRight: '6px' }}
                      className="slider_np"
                      src={prev}
                      alt="slider_np"
                    />
                    Previous
                  </button>
                  <button
                    onClick={() => sliderRef.current.slickNext()}
                    className="custom-next-arrow"
                  >
                    Next
                    <img
                      style={{ marginLeft: '6px' }}
                      className="slider_np"
                      src={next}
                      alt="slider_np"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <Slider ref={sliderRef} {...settings}>
            <div className="slide">
              <div className="row mb-4">
                <div className="col-7">
                  {/* <div className="slide_image">
                    <img className="slide_imageImg" src={man2} alt="Slide 1" />
                  </div> */}
                  <img className="slide_image" src={prem} alt="Slide 1" />
                </div>
                <div className="col-5">
                  <div className="star_g">
                    <img className="star" src={star} alt="s" />
                    <img className="star" src={star} alt="s" />
                    <img className="star" src={star} alt="s" />
                    <img className="star" src={star} alt="s" />
                    <img className="star" src={starN} alt="s" />
                  </div>
                </div>
              </div>
              <h2 className="slide_head">Prem</h2>
              <h3 className="travel_name">Travel Story</h3>
              <h4 className="slide_test">
                We'd once again wish to thank you and the rest of the team for
                getting the job done with precision and quality
                <br></br>
                The service skills of your team members were amazing
                <br></br>
                Super show! Thanks again
              </h4>
            </div>
            <div className="slide">
              <div className="row mb-4">
                <div className="col-7">
                  <img className="slide_image" src={gril} alt="Slide 1" />
                </div>
                <div className="col-5">
                  <div className="star_g">
                    <img className="star" src={star} alt="s" />
                    <img className="star" src={star} alt="s" />
                    <img className="star" src={star} alt="s" />
                    <img className="star" src={star} alt="s" />
                    <img className="star" src={star} alt="s" />
                  </div>
                </div>
              </div>
              <h2 className="slide_head">Cathy Chang</h2>
              <h4 className="slide_test">
                It was a well organised ticketing service
              </h4>
            </div>
            <div className="slide">
              <div className="row mb-4">
                <div className="col-7">
                  <img className="slide_image" src={dumm} alt="Slide 1" />
                </div>
                <div className="col-5">
                  <div className="star_g">
                    <img className="star" src={star} alt="s" />
                    <img className="star" src={star} alt="s" />
                    <img className="star" src={star} alt="s" />
                    <img className="star" src={star} alt="s" />
                    <img className="star" src={starN} alt="s" />
                  </div>
                </div>
              </div>
              <h2 className="slide_head">Dumalka Kariyawasam</h2>
              <h4 className="slide_test">
                One ticket is one of the best and easiest way of purchasing
                tickets. They are very convenient, nicely organize their events
                and they have bunch of young energetic people who helps
                customers to purchase their tickets without any hassle. Highly
                recommended and wish you all the very best
              </h4>
            </div>
            <div className="slide">
              <div className="row mb-4">
                <div className="col-7">
                  <img className="slide_image" src={boy} alt="Slide 1" />
                </div>
                <div className="col-5">
                  <div className="star_g">
                    <img className="star" src={star} alt="s" />
                    <img className="star" src={star} alt="s" />
                    <img className="star" src={star} alt="s" />
                    <img className="star" src={star} alt="s" />
                    <img className="star" src={star} alt="s" />
                  </div>
                </div>
              </div>
              <h2 className="slide_head">Sherone Joseph</h2>
              <h4 className="slide_test">
                It was an amazing event organized by OneTicket and the team
                played efficiently and effectively. Hassle free and very
                respective!
              </h4>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default SlideShow;
