import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Event from '../components/Event';
import BannerCarousel from '../components/BannerCarousel';
import LoadingSpinner from '../components/LoadingSpinner';
import { assets, environment } from '../data';
import SlideShow from '../components/SlideShow';
import Oneticketimg from '../assets/img/onepaylogo.png';

// import { useMeta } from '../MetaContext';

export default function AboutUs() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribeLoading, setIsSubscribeLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  // const { setMeta } = useMeta();
  useEffect(() => {
    let subdomain = window.location.hostname.split('.')[0];
    if (subdomain === 'oneticket' || subdomain === 'localhost') {
      subdomain = '';
    }
    //setSubDomain(subdomain);
    console.log('Subdomain:', subdomain);
    if (subdomain == 'dev-organization') {
      getData('');
    } else {
      getData(subdomain);
    }
  }, []);
  function getData(subdomain) {
    setIsLoading(true);

    axios
      .get(
        `${environment.BASE_URL2}/oneticket/user/event/get/?limit=10000&page=1&sub_domain=${subdomain}`,
      )
      .then((res) => {
        const data = res?.data;
        console.log(data.data);
        if (data?.status === 223) {
          navigate('/notfound');
        }
        if (data?.status === 200) {
          console.log('have');
          setEvents(data.data);

          // let b = [];
          // data.data.data.forEach((e) => {
          //   if (e.event_banner) {
          //     b.push(e.event_banner);
          //   }
          // });
          // console.log(b);
          // setBanners(b);
        } else {
          console.error(data.message || 'Getting events failed!');
          toast.error('Getting events failed!', { id: 'getEvents' });
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error('Oh! Something went wrong', { id: 'getEvents' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <div className="container" style={{ marginTop: '-64px' }}>
        <div className="events">
          <div className="content">
            <div className="row align-items-center">
              <div className="col-md-8 col-12" style={{ padding: '0' }}>
                <div className="d-flex flex-column h-100 justify-content-center">
                  <p className="our_story mb-0">Our story</p>
                  <h4 className="our_story_text">
                    Leading online ticketing platform.
                  </h4>
                </div>
              </div>
              <div
                className="col-md-4 col-12 oneticket_res"
                style={{ padding: '0' }}
              >
                <div
                  className="d-flex h-100 justify-content-center align-items-center"
                  style={{ float: 'right' }}
                >
                  <img
                    className="oneticket_logo"
                    src={Oneticketimg}
                    alt="OneTicket Logo"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="about_line"></div>
          {/* <div> */}
          <h4 className="about_h1 mb-3">About us</h4>
          <p className="about_p1">
            OneTicket is a Onepay-affiliated brand that provides services for
            event organisers to sell tickets online. Launched in 2022, OneTicket
            has organised 40 events, including both public and private
            gatherings in various locations.
          </p>
          <br></br>
          <p className="about_p1">
            OneTicket is a low-cost and reliable ticket-selling platform that
            adheres to the ISO 27001 information data security standard to
            safeguard client data and payment-related information.
          </p>
          <br></br>
          <p className="about_p1">
            As one of the fastest-growing ticket marketplaces, OneTicket
            includes events in music, sports, art, theatre, and more. It is
            capable of accommodating events of all types, sizes, and
            complexities with state-of-the-art technology.
          </p>

          {/* </div> */}

          <div style={{ marginTop: '5rem' }} className="events-2">
            <div className="events-2-1 d-flex align-items-center justify-content-between mb-3">
              <h6>Events</h6>
            </div>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {events.length > 0 ? (
                  <div className="list">
                    {events.map((e, i) => (
                      <Event key={i} event={e} />
                    ))}
                  </div>
                ) : (
                  <p className="my-5">No available events!</p>
                )}
              </>
            )}
          </div>
          <div className="events-1 row mt-5">
            <SlideShow />

            <div className="events-1-2 d-none d-lg-block col-lg-6"></div>
          </div>
        </div>
      </div>
    </>
  );
}
