import React from 'react';

import { assets } from '../data';

export default function LeftOffCanvasSpecific({
  children,
  isOpen,
  heading,
  close,
}) {
  function closeCanvas() {
    close();
  }

  // disable page scrolling
  if (isOpen) {
    document.body.classList.add('no-scroll');
    document
      .getElementById('left-off-canvas-wrapper-specific')
      ?.classList.add('open');
    document.getElementById('left-off-canvas-specific')?.classList.add('open');
  } else {
    document.body.classList.remove('no-scroll');
    document
      .getElementById('left-off-canvas-wrapper-specific')
      ?.classList.remove('open');
    document
      .getElementById('left-off-canvas-specific')
      ?.classList.remove('open');
  }

  return (
    <div
      className="left-off-canvas-wrapper-specific"
      id="left-off-canvas-wrapper-specific"
    >
      <div className="left-off-canvas-specific" id="left-off-canvas-specific">
        <div className="header d-flex align-items-center justify-content-between mb-3">
          <h3>{heading}</h3>

          <button onClick={closeCanvas} className="close-btn">
            <img
              src={assets.close_new}
              width={24}
              alt="close offcanvas icon"
              loading="lazy"
            />
          </button>
        </div>

        {children}
      </div>

      <div className="backdrop" onClick={closeCanvas}></div>
    </div>
  );
}
