import React, { useEffect } from 'react';

import { assets } from '../data';

export default function SuccessModal({ close }) {
  useEffect(() => {
    document.body.classList.add('no-scroll');
  }, []);

  function closeModal() {
    document.body.classList.remove('no-scroll');
    close();
  }

  return (
    <div className="success-modal-wrapper" id="success-modal-wrapper">
      <div className="success-modal" id="success-modal">
        <div className="d-flex align-items-center justify-content-end">
          <button onClick={closeModal} className="close-btn">
            <img
              src={assets.close_icon}
              alt="close offcanvas icon"
              loading="lazy"
            />
          </button>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center my-4">
          <img
            src={assets.tick_icon}
            alt="tick icon"
            loading="lazy"
            className="tick"
          />

          <h5 className="mt-3 mb-3">Ticket Purchase Successful!</h5>

          <p>Please check your email for the Purchase Confirmation</p>
        </div>
      </div>

      <div className="backdrop" id="backdrop"></div>
    </div>
  );
}
