import React, { useEffect } from 'react';

export default function EventDetails({ content }) {
  useEffect(() => {
    const el = document.getElementById('event-details');
    if (content) {
      el.innerHTML = content;
    } else {
      el.innerHTML = '<p>No event details!</p>';
    }
  });

  return (
    <div className="event-details">
      <h5 className="mb-4">Event Terms & Conditions</h5>

      <div id="event-details"></div>

      {/* <div className="mb-4">
        <p>Special Instructions for the event</p>

        <ol>
          <li>Event gates will be opened at 4.30PM .</li>

          <li>
            Tickets are priced at LKR 3,000.00 (Back Seats - Free Seating) and LKR 5,000.00 (Front Seats - Free
            Seating).
          </li>

          <li>Event will be adhered to the Government Rules and Regulations.</li>

          <li>
            Along with the Ticket Reservation email you will receive a QR Code. Please show the QR code to the officials
            at the event entrance in order to get your entrance passes.
          </li>

          <li>
            You must bring your NIC / Passport or Driving License to the event. The Identity proof is required to get
            the entrance pass.
          </li>

          <li>
            OneTicket and Onepay are the Payment Service Providers for the event. The organizer’s decision will be the
            final decision when it comes to any matter related to the event.
          </li>

          <li>The purchased tickets will be non-refundable and non-transferrable.</li>
        </ol>
      </div> */}

      {/* <div>
        <p>ප්‍රසංගය සඳහා විශේෂ උපදෙස්</p>

        <ol>
          <li>ප.ව. 4.30 ට ප්‍රසංගයේ දොරටු විවෘත කෙරේ.</li>

          <li>ප්‍රවේශ පත්‍ර වල මිල ගණන් රු. 3,000.00 (පසුපෙළ නිදහස් ආසන) සහ 5,000.00 (ඉදිරිපෙළ නිදහස් ආසන) වේ.</li>

          <li>මෙම ප්‍රසංගය රජයේ නීති හා රෙගුලාසි වලට අනුකුලව පැවැත්වේ.</li>

          <li>
            ටිකට් පත මිලදී ගැනීමේදී ඔබට ලැබෙන විද්‍යුත් තැපැල් පණිවිඩය සමග QR කේතයක් ලැබෙනු ඇත. ප්‍රසංගයට ඇතුලත්වීමේ
            දොරටුවේදී මෙම QR කේතය උත්සව සංවිධායකයින්ට පෙන්විය යුතුය.එවිට ඔබට මුද්‍රිත ටිකට් පතක් දොරටුවෙන් ලබා ගත හැක.
          </li>

          <li>
            ප්‍රසංගයට ඇතුලත් වීම සඳහා ඔබගේ ජාතික හැඳුනුම්පත / ගමන් බලපත්‍රය (Passport) හෝ රියදුරු බලපත්‍රය ඉදිරිපත් කළ
            යුතුය.
          </li>

          <li>
            OneTicket හා Onepay ප්‍රසංගය සඳහා ගෙවීම් සේවා සපයන්නන් වේ. ප්‍රසංගයට අදාළ ඕනෑම කරුණක් සම්බන්ධයෙන්
            සංවිධායකයින්ගේ තීරණය අවසාන තීරණය වේ.
          </li>

          <li> ටිකට් පත් මිලදී ගත් පසු ආපසු මුදල් නොගෙවන අතර ටිකට් පත් හුවමාරු කල නොහැක.</li>
        </ol>
      </div> */}
    </div>
  );
}
