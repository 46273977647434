import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';

import EventDetails from '../components/EventDetails';
import PayForm from '../components/PayForm';
import SuccessModal from '../layouts/SuccessModal';
import LoadingSpinner from '../components/LoadingSpinner';
import { formatDate } from '../common';
import { environment, assets } from '../data';
import CoperatePayForm from '../components/CoperatePayForm';
import { countries } from 'countries-list';
import { useMeta } from '../MetaContext';

export default function CoperateUserCartSpecific() {
  //const { custom_fields } = route.params;
  const location = useLocation();
  const url = window.location.href;
  const parts = url.split('/');
  const assignee_id = parts[parts.length - 1];
  const { custom_fields } = location.state || [];
  const { event_id, transaction_id } = useParams();
  const navigate = useNavigate();
  const [eventDetails, setEventDetails] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [customFields, setCustomField] = useState([]);
  const [total, setTotal] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalExists, setIsModalExists] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isCompalsaryTicketCount, setIsCompalsaryTicketCount] = useState(0);
  const [error, setError] = useState('');
  const [errorTicketId, setErrorTicketId] = useState();
  const [isLimited, setLimit] = useState(false);
  const [isCoperate, setIsCoperate] = useState(true);
  const [errorPin, setErrorPin] = useState('');
  const [isVerify, setVerify] = useState(false);
  const [selectedExtension, setSelectedExtension] = useState('');
  const { setMeta } = useMeta();

  useEffect(() => {
    console.log('Hello This is assignee_id :' + assignee_id);
    getData();
  }, []);
  useEffect(() => {
    if (eventDetails) {
      setMeta({
        title: eventDetails.event_name || 'Event Details',
        description: eventDetails.venue || 'Details of the selected event.',
        url: window.location.href,
        image: eventDetails.event_banner
          ? `${environment.aws}/${eventDetails.event_banner}`
          : assets.sample_img,
      });
    }
  }, [eventDetails, setMeta]);

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fname: '',
      pmiNumber: '',
      companyName: '',
      designation: '',
      contactNumber: '',
      linkedInPeofile: '',
    },
    mode: 'onChange',
  });
  function onSubmit(data) {
    // if (eventDetails?.is_membership_required === 1 && !isVerify) {
    //   return;
    // }
    // if (errorPin !== '') {
    //   toast.error('Please! Verify your membership');
    //   return;
    // }
    console.log(data);
    setIsLoading(true);
    let body = {
      assignee_id: assignee_id,
      assignee_name: data.fname,
      assignee_phone_no: selectedExtension + data.contactNumber,
      designation: data.designation,
      assignee_pin: data.pmiNumber,
      linkedin_profile: data.linkedInPeofile,
      assignee_company_name: data.companyName,
    };
    axios
      .post(`${environment.BASE_URL2}/events/update-assignee/`, body)
      .then((res) => {
        console.log(res);
        const data = res?.data;
        // console.log(data);

        if (data?.status === 100) {
          toast.success('Success! Details saved');
          navigate('/');
          setIsLoading(false);
          reset({
            fname: '',
            pmiNumber: '',
            companyName: '',
            designation: '',
            contactNumber: '',
            linkedInPeofile: '',
          });
        } else {
          setIsLoading(false);
          toast.error(data.message || 'Failed!');
          console.error(data.message || 'Transaction verification failed!');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const handleExtensionChange = (e) => {
    setSelectedExtension(e.target.value);
  };
  const validatePin = async (pinValue) => {
    if (eventDetails?.is_membership_required === 0) {
      return;
    }
    const body = {
      event_id: eventDetails.event_details,
      verification_id: getValues('pin'),
    };
    axios
      .post(`${environment.BASE_URL2}/events/verify-membership/`, body, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        // console.log(res)
        const data = res?.data;
        // console.log(data);

        if (data?.status === 100) {
          toast.success('Success! Verify membership');
          setVerify(true);
          setErrorPin('');
        } else {
          setVerify(false);
          errors.verificationId.message = data.message;
          console.error(data.message || 'Membership verification failed!');
          setErrorPin('Invalide membership pin!');
        }
      })
      .catch((err) => {
        console.error(err);
        setErrorPin('Membership verification failed!');
        //toast.error('Oh! Something went wrong!', { id: 'buyTickets' });
      });
  };

  function getData() {
    setIsLoading(true);

    axios
      .get(
        `${environment.BASE_URL2}/events/event-details/?event_id=${event_id}`,
      )
      .then((res) => {
        // console.log(res);
        const data = res?.data;
        //console.log(data.data);

        if (data?.status === 100) {
          setEventDetails(data?.data);

          let t = data?.data?.tickets;
          t.map((t) => {
            let ticket = t;
            ticket['count'] = 0;
            if (parseFloat(ticket.remaining_tickets) < 0) {
              ticket.remaining_tickets = 0;
            }
            return ticket;
          });

          setTickets(t);
          setCustomField(data?.data?.custom_fields);
        } else {
          console.error(data.message || 'Getting event details failed!');
          toast.error('Getting event details failed!', {
            id: 'getEventDetails',
          });
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error('Oh! Something went wrong', { id: 'getEventDetails' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <div className="container">
        <div className="cart">
          <div className="welcome-note">
            Great news..! You got invited to the{' '}
            <span style={{ color: '#23A078' }}>
              {eventDetails?.event_name || 'Not Available'}
            </span>{' '}
            event 🎉
          </div>
          <div className="cart-1">
            <div className="img d-flex justify-content-center">
              <img
                src={
                  eventDetails?.event_banner
                    ? environment.aws + '/' + eventDetails.event_banner
                    : assets.sample_img
                }
                alt="event cover"
                loading="lazy"
              />
            </div>

            <div className="info">
              <h3 className="name">
                {eventDetails?.event_name || 'Not Available'}
              </h3>

              <p className="date">
                {event_id === 'FRQM118C368CAF159CBEE'
                  ? '2024/02/10 (Saturday) 6:00 PM'
                  : event_id === 'DH36118E3A74B95C14157'
                  ? '2024/03/16 (Saturday) 7:00 PM'
                  : event_id === 'QX5C118E404233F1032E0'
                  ? '2024/05/31 (Friday), 8:30 AM – 6:00 PM'
                  : event_id === 'UIPA118E4147B2399589F'
                  ? '2024/04/26 (Friday) 6:30 PM'
                  : formatDate(eventDetails?.event_datetime)}
              </p>

              <div className="info-1 mt-3 mb-3">
                <h5>Organizer</h5>

                <p>{eventDetails?.organizer || 'Not Available'}</p>
              </div>

              <div className="info-1 mt-3 mb-3">
                <h5>Venue</h5>

                <p>{eventDetails?.venue || 'Not Available'}</p>
              </div>
              <div className="info-1 mt-3 mb-3">
                <h5 className="mb-3">Fill your details</h5>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="ticket row d-flex align-items-center">
                    <div className="col-12 col-sm-6 d-flex flex-column align-items-start mb-4">
                      <label className="pb-2">
                        Full name <span className="required-sign">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.fname ? 'on-error' : ''
                        }}`}
                        placeholder="Write your first name"
                        {...register('fname', {
                          required: {
                            value: true,
                            message: 'Please write your first name',
                          },
                        })}
                      />
                      {errors.fname ? (
                        <p className="error-text">{errors.fname.message}</p>
                      ) : null}
                    </div>
                    <div className="col-12 col-sm-6 d-flex flex-column align-items-start mb-4">
                      <label className="pb-2">PMI number</label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.pin ? 'on-error' : ''
                        }`}
                        placeholder="Enter PMI number"
                        {...register('pmiNumber', {
                          // validate: validatePin,
                        })}
                      />
                      {/* {errorPin !== '' ? <p className="error-text">{errorPin}</p> : null} */}
                    </div>
                  </div>
                  <div className="ticket row d-flex align-items-center">
                    <div className="col-12 col-sm-6 d-flex flex-column align-items-start mb-4">
                      <label className="pb-2">
                        Company name <span className="required-sign">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.companyName ? 'on-error' : ''
                        }`}
                        placeholder="Write your company name"
                        {...register('companyName', {
                          required: {
                            value: true,
                            message: 'Please write your company name',
                          },
                        })}
                      />
                      {errors.companyName ? (
                        <p className="error-text">
                          {errors.companyName.message}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-12 col-sm-6 d-flex flex-column align-items-start mb-4">
                      <label className="pb-2">
                        Designation <span className="required-sign">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.designation ? 'on-error' : ''
                        }`}
                        placeholder="Write your designation"
                        {...register('designation', {
                          required: {
                            value: true,
                            message: 'Please write your designation',
                          },
                        })}
                      />
                      {errors.designation ? (
                        <p className="error-text">
                          {errors.designation.message}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="ticket row d-flex align-items-center">
                    <div className="col-12 col-sm-6 d-flex flex-column align-items-start mb-4">
                      <label className="pb-2">
                        Contact number <span className="required-sign">*</span>
                      </label>
                      <div
                        className="form-control d-flex flex-column flex-sm-row align-items-start"
                        style={{ borderRadius: 5 }}
                      >
                        <select
                          className="col-3"
                          style={{
                            border: 'none',
                            borderStyle: 'none',
                            outline: 'none',
                            margin: 0,
                          }}
                          id="extensionDropdown"
                          value={selectedExtension}
                          onChange={handleExtensionChange}
                        >
                          {/* <option value="">Select Extension</option> */}
                          {Object.entries(countries).map(([code, country]) => (
                            <option
                              key={code}
                              value={`+${country.phone}`}
                            >{`+${country.phone}`}</option>
                          ))}
                        </select>
                        <input
                          type="tel"
                          style={{
                            border: 'none',
                            borderStyle: 'none',
                            outline: 'none',
                            backgroundColor: 'none',
                          }}
                          className={`col-9 ${
                            errors.contactNumber ? 'on-error' : ''
                          }`}
                          placeholder="Write your contact number"
                          {...register('contactNumber', {
                            required: {
                              value: true,
                              message: 'Please write your phone number',
                            },
                            pattern: {
                              value: /^\+?[0-9]{8}[0-9]*$/,
                              message: 'Invalid phone number',
                            },
                          })}
                        />
                      </div>

                      {errors.contactNumber ? (
                        <p className="error-text">
                          {errors.contactNumber.message}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-12 col-sm-6 d-flex flex-column align-items-start mb-4">
                      <label className="pb-2">Linkedin profile</label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.linkedInPeofile ? 'on-error' : ''
                        }`}
                        placeholder="Write your linkedIn url"
                        {...register('linkedInPeofile', {
                          // validate: validatePin,
                        })}
                      />
                      {/* {errorPin !== '' ? <p className="error-text">{errorPin}</p> : null} */}
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center justify-content-end mt-5">
                    <button className="primary-btn buy-btn" type="submit">
                      {isLoading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Loading...</span>
                        </>
                      ) : (
                        'Get the Ticket'
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="cart-2 mt-5">
            <EventDetails content={eventDetails?.event_description} />
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="loading-div">
          <LoadingSpinner />
        </div>
      ) : null}
    </>
  );
}
