import moment from 'moment';

export function formatDate(datetime) {
  if (!datetime) {
    return '';
  }

  return moment(datetime).format('YYYY/MM/DD (dddd) h:mm a');
}
export function formatDate2(datetime) {
  if (!datetime) {
    return '';
  }

  return moment(datetime).format('ddd, MMMM D, YYYY, h:mm A');
}

export function formatUrl(url) {
  if (!url) {
    return '';
  }

  // return String(url ? url : '').replaceAll(' ', '%20');
  return String(url);
}
