// import React, { createContext, useContext, useState, useEffect } from 'react';

// const MetaContext = createContext();

// export const MetaProvider = ({ children }) => {
//   const [meta, setMeta] = useState({
//     title: 'Default Title',
//     description: 'Default Description',
//     image: 'default-image-url',
//     url: window.location.href,
//   });

//   useEffect(() => {
//     document.title = meta.title;

//     const metaTags = {
//       description: document.querySelector('meta[name="description"]'),
//       ogTitle: document.querySelector('meta[property="og:title"]'),
//       ogDescription: document.querySelector('meta[property="og:description"]'),
//       ogImage: document.querySelector('meta[property="og:image"]'),
//       ogUrl: document.querySelector('meta[property="og:url"]'),
//       twitterTitle: document.querySelector('meta[name="twitter:title"]'),
//       twitterDescription: document.querySelector(
//         'meta[name="twitter:description"]',
//       ),
//       twitterImage: document.querySelector('meta[name="twitter:image"]'),
//       twitterUrl: document.querySelector('meta[name="twitter:url"]'),
//     };

//     if (metaTags.description)
//       metaTags.description.setAttribute('content', meta.description);
//     if (metaTags.ogTitle) metaTags.ogTitle.setAttribute('content', meta.title);
//     if (metaTags.ogDescription)
//       metaTags.ogDescription.setAttribute('content', meta.description);
//     if (metaTags.ogImage) metaTags.ogImage.setAttribute('content', meta.image);
//     if (metaTags.ogUrl) metaTags.ogUrl.setAttribute('content', meta.url);
//     if (metaTags.twitterTitle)
//       metaTags.twitterTitle.setAttribute('content', meta.title);
//     if (metaTags.twitterDescription)
//       metaTags.twitterDescription.setAttribute('content', meta.description);
//     if (metaTags.twitterImage)
//       metaTags.twitterImage.setAttribute('content', meta.image);
//     if (metaTags.twitterUrl)
//       metaTags.twitterUrl.setAttribute('content', meta.url);
//   }, [meta]);

//   return (
//     <MetaContext.Provider value={{ meta, setMeta }}>
//       {children}
//     </MetaContext.Provider>
//   );
// };

// export const useMeta = () => useContext(MetaContext);

// now
import React, { createContext, useContext, useState } from 'react';

const MetaContext = createContext();

export const MetaProvider = ({ children }) => {
  const [meta, setMeta] = useState({
    title: "Sri Lanka's convient event hosting platform",
    description:
      'Experience seamless ticket purchasing for your next event with OneTicket! Enjoy the lowest processing charge at just 3.5% – the best in the market With ISO 27001 security standards. Call us today 0760523025',
    image: 'default-image-url',
    url: window.location.href,
  });

  return (
    <MetaContext.Provider value={{ meta, setMeta }}>
      {children}
    </MetaContext.Provider>
  );
};

export const useMeta = () => useContext(MetaContext);

// MetaContext.js
// import React, { createContext, useContext, useState } from 'react';

// const MetaContext = createContext();

// export const MetaProvider = ({ children }) => {
//   const [meta, setMeta] = useState({
//     title: 'Default Title',
//     description: 'Default Description',
//     image: 'default-image-url',
//   });

//   return (
//     <MetaContext.Provider value={{ meta, setMeta }}>
//       {children}
//     </MetaContext.Provider>
//   );
// };

// export const useMeta = () => useContext(MetaContext);
