import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';

import EventDetails from '../components/EventDetails';
import PayForm from '../components/PayForm';
import SuccessModal from '../layouts/SuccessModal';
import LoadingSpinner from '../components/LoadingSpinner';
import { formatDate } from '../common';
import { environment, assets } from '../data';
import CoperatePayForm from '../components/CoperatePayForm';
import PayFormSpecific from '../components/PayFormSpecific';
import { event } from 'jquery';
import { useMeta } from '../MetaContext';

export default function Cart() {
  //const { custom_fields } = route.params;
  const location = useLocation();
  const { custom_fields } = location.state || [];
  const { event_id, transaction_id } = useParams();
  const [eventDetails, setEventDetails] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [customFields, setCustomField] = useState([]);
  const [total, setTotal] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalExists, setIsModalExists] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isCompalsaryTicketCount, setIsCompalsaryTicketCount] = useState(0);
  const [error, setError] = useState('');
  const [errorTicketId, setErrorTicketId] = useState();
  const [isLimited, setLimit] = useState(false);
  const [isCoperate, setIsCoperate] = useState(false);
  const [isPopupVisible, setisPopupVisible] = useState(false);
  const [validateCode, setValidateCode] = useState('');
  const [isValidateCode, setisValidateCode] = useState(true);
  const [redeemCode, setRedeemCode] = useState('');
  const [shareRedeemCode, setShareRedeemCode] = useState('');
  const [isRedeemOnce, setIsRedeemOnce] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [isCouponRedeem, setIsRedeemCoupon] = useState(false);
  const [isNotMemberShipVerified, setIsNotMemberShipVerified] = useState(false);
  const { setMeta } = useMeta();

  useEffect(() => {
    if (transaction_id) {
      verifyTransaction();
    }

    console.log('Hello This is location dta' + window.location.href);
    getData();
  }, []);
  useEffect(() => {
    if (eventDetails) {
      setMeta({
        title: eventDetails.event_name || 'Event Details',
        url: window.location.href,
        description: eventDetails.venue || 'Details of the selected event.',
        // image: environment.aws + '/' + eventDetails.event_banner || 'Details of the selected event.'
        image: eventDetails.event_banner
          ? `${environment.aws}/${eventDetails.event_banner}`
          : assets.sample_img,
      });
    }
  }, [eventDetails, setMeta]);

  const handleCodeValidate = (event) => {
    setValidateCode(event.target.value);
  };

  const handleCoupenCode = (event) => {
    setRedeemCode(event.target.value);
    setShareRedeemCode(event.target.value);
    //console.log('Coupon data:', event.target.value);
  };

  function submitCoupon() {
    console.log('Coupon data:', redeemCode);
    if (filterTickets().length === 0) {
      toast.error('You must buy one or more tickets!', {
        id: 'noTicketsSelected',
      });
      return;
    }
    verifyTicketWithCoupon();
  }

  function verifyMembershipCode() {
    const dataPost = {
      event_id: event_id,
      verification_id: validateCode,
    };
    axios
      .post(`${environment.BASE_URL4}/events/verify-membership/`, dataPost, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        // console.log(res)
        const data = res?.data;
        // console.log(data);

        if (data?.status === 100) {
          toast.success('Success! Verify membership');
          setIsNotMemberShipVerified(false);
          setisPopupVisible(false);
          setValidateCode('');
        } else {
          toast.error('Failed! Verify membership');
          console.error(data.message || 'Membership verification failed!');
          setValidateCode('');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function verifyTicketWithCoupon() {
    if (redeemCode === '') {
      toast.error('Please write the redeem code');
      return;
    }
    let ts = [];

    tickets.forEach((t) => {
      let t1 = {
        ticket_id: t.id,
        count: t.count,
      };
      ts.push(t1);
    });
    const dataPost = {
      event_id: event_id,
      coupon_code: redeemCode,
      tickets: ts,
    };
    axios
      .post(
        `${environment.BASE_URL2}/oneticket/user/event/transaction-session/validate-coupon/`,
        dataPost,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then((res) => {
        // console.log(res)
        const data = res?.data;
        // console.log(data);

        if (data?.status === 100) {
          setDiscount(data.data.discounted_amount);
          setTotal(data.data.net_amount);
          setIsRedeemCoupon(true);
          setRedeemCode('');
          setIsRedeemOnce(true);

          toast.success('Success! Coupon redeem');
        } else if (data?.status === 101) {
          toast.error(data.message);
        } else {
          toast.error('Failed! Coupon redeem');
          console.error(data.message || 'Coupon redeem failed!');
        }
      })
      .catch((err) => {
        toast.error('Failed! Invalid coupon code');
        console.error(err);
      });
  }
  function verifyTicketWithoutCoupon() {
    openModal();
    // let ts = [];

    // tickets.forEach((t) => {
    //   let t1 = {
    //     ticket_id: t.id,
    //     count: t.count,
    //   };
    //   ts.push(t1);
    // });
    // const dataPost = {
    //   event_id: event_id,
    //   coupon_code: '',
    //   tickets: ts,
    // };
    // axios
    //   .post(`${environment.BASE_URL2}/events/verify-ticket/`, dataPost, {
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //   })
    //   .then((res) => {
    //     // console.log(res)
    //     const data = res?.data;
    //     // console.log(data);

    //     if (data?.status === 100) {
    //       setTotal(data.data.net_amount);
    //       openModal();
    //       toast.success('Success! Verify membership');
    //     } else {
    //       toast.error('Failed! Verify membership');
    //       openModal();
    //       console.error(data.message || 'Membership verification failed!');
    //     }
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
  }

  function verifyTransaction() {
    axios
      .get(
        `${environment.BASE_URL2}/events/event-transaction-status/?onepay_transaction_id=${transaction_id}`,
      )
      .then((res) => {
        // console.log(res)
        const data = res?.data;
        // console.log(data);

        if (data?.status === 100) {
          if (data.data.status === 1) {
            // show success popup
            setIsSuccess(true);
          }
        } else {
          console.error(data.message || 'Transaction verification failed!');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getData() {
    setIsLoading(true);

    axios
      .get(
        `${environment.BASE_URL2}/oneticket/user/event/get-details/?id=${event_id}`,
      )
      .then((res) => {
        console.log(res);
        const data = res?.data;
        console.log(data.data);

        if (data?.status === 200) {
          setEventDetails(data?.data);
          setIsCoperate(data.data.is_ticket_assign_required);
          //setisPopupVisible(data.data.is_membership_required);
          //setIsNotMemberShipVerified(data.data.is_membership_required);

          let t = data?.data?.tickets;
          t.map((t) => {
            let ticket = t;
            ticket['count'] = 0;
            if (parseFloat(ticket.remaining_tickets) < 0) {
              ticket.remaining_tickets = 0;
            }
            return ticket;
          });

          setTickets(t);
          setCustomField(data?.data?.fields);
        } else {
          console.error(data.message || 'Getting event details failed!');
          toast.error('Getting event details failed!', {
            id: 'getEventDetails',
          });
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error('Oh! Something went wrong', { id: 'getEventDetails' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function addQuantity(id) {
    let ts = [...tickets];
    console.log(ts);
    if (event_id === 'Z17Y118E5804C53F9FEDB') {
      ts.forEach((t) => {
        //console.log(t.id);
        //toast.error('Getting event details failed!', { id: 'getEventDetails' });
        if (t.id === id && id === 242) {
          if (t.count > 0) {
            setError('You can only buy up to 1 subsidized dinner ticket');
            setErrorTicketId(t.id);
            setLimit(true);
            // toast.error('You can only buy up to 5 additional dinner contribution tickets', {
            //   id: 'noDinnerTicketSelected',
            // });
          } else {
            t.count += 1;
            t.remaining_tickets -= 1;
            setIsCompalsaryTicketCount(t.count);
          }
        } else if (t.id === id && id === 244) {
          if (t.count > 1) {
            setError('You can only buy up to 2 match tickets');
            setErrorTicketId(t.id);
            setLimit(true);
            // toast.error('You can only buy up to 2 match tickets', {
            //   id: 'noDinnerTicketSelected',
            // });
          } else {
            t.count += 1;
            t.remaining_tickets -= 1;
          }
        } else if (t.id === id && id === 243) {
          //
          t.count += 1;
          t.remaining_tickets -= 1;
        }
      });
    } else if (event_id === 'LV9W118E5804BD17140D1') {
      ts.forEach((t) => {
        if (t.id === id && id === 236) {
          t.count += 1;
          t.remaining_tickets -= 1;
          setIsCompalsaryTicketCount(t.count);
        } else if (t.id === id && id === 237) {
          t.count += 1;
          t.remaining_tickets -= 1;
        }
      });
    } else if (event_id === 'A73Z118E5804C2B52A65A') {
      ts.forEach((t) => {
        if (t.id === id && id === 239) {
          if (t.count > 0) {
            setError('You can only buy up to 1 dinner contribution ticket');
            setErrorTicketId(t.id);
            setLimit(true);
          } else {
            t.count += 1;
            t.remaining_tickets -= 1;
            setIsCompalsaryTicketCount(t.count);
          }
        } else if (t.id === id && id === 241) {
          if (t.count > 1) {
            setError('You can only buy up to 2 match tickets');
            setErrorTicketId(t.id);
            setLimit(true);
          } else {
            t.count += 1;
            t.remaining_tickets -= 1;
          }
        } else if (t.id === id && id === 240) {
          // if (t.count > 4) {
          //   setError(
          //     'You can only buy up to 5 additional dinner contribution tickets',
          //   );
          //   setErrorTicketId(t.id);
          //   setLimit(true);

          // } else {
          //   t.count += 1;
          //   t.remaining_tickets -= 1;
          // }
          t.count += 1;
          t.remaining_tickets -= 1;
        }
      });
    } else {
      ts.forEach((t) => {
        if (t.id === id && 1 <= t.remaining_tickets) {
          t.count += 1;
          t.remaining_tickets -= 1;
        }
      });
    }

    setTickets(ts);
    calculateTotal();
  }

  function removeQuantity(id) {
    let ts = [...tickets];
    setLimit(false);
    ts.forEach((t) => {
      if (t.id === id && t.count > 0) {
        t.count -= 1;
        t.remaining_tickets += 1;
        if (t.id === 242) {
          setIsCompalsaryTicketCount(t.count);
        }
        if (t.id === 239) {
          setIsCompalsaryTicketCount(t.count);
        }
        if (t.id === 236) {
          setIsCompalsaryTicketCount(t.count);
        }
        if (t.id === 236) {
          setIsCompalsaryTicketCount(t.count);
        }
      }
    });

    setTickets(ts);
    calculateTotal();
  }

  function calculateTotal() {
    let tt = 0;

    tickets.forEach((t) => {
      tt += t.ticket_amount * t.count;
    });

    setTotal(tt - discount);
  }

  function buy() {
    if (!isCouponRedeem && event_id === 'MNIN118E5D6A6FE1A71C8') {
      toast.error('Coupen code must be required');
      return;
    }
    if (filterTickets().length === 0) {
      toast.error('You must buy one or more tickets!', {
        id: 'noTicketsSelected',
      });
      return;
    }
    if (isCompalsaryTicketCount === 0 && event_id === 'Z17Y118E5804C53F9FEDB') {
      toast.error('Compulsory to buy subsidized dinner ticket!', {
        id: 'noTicketsSelected',
      });
      return;
    }
    if (isCompalsaryTicketCount === 0 && event_id === 'A73Z118E5804C2B52A65A') {
      toast.error('Compulsory to buy dinner contribution ticket!', {
        id: 'noTicketsSelected',
      });
      return;
    }
    if (isCompalsaryTicketCount === 0 && event_id === 'LV9W118E5804BD17140D1') {
      toast.error('Compulsory to buy bottle donor contribution ticket!', {
        id: 'noTicketsSelected',
      });
      return;
    }
    // if (isNotMemberShipVerified) {
    //   toast('Oops! You have to verify membership');
    //   return;
    // }
    if (!isCouponRedeem) {
      verifyTicketWithoutCoupon();
    } else {
      openModal();
    }
  }

  function validateCoperateCode() {}

  function openModal() {
    setIsModalExists(true);
    setTimeout(() => {
      setIsModalOpen(true);
    }, 100);
  }

  function closeModal() {
    setIsModalOpen(false);
    setTimeout(() => {
      setIsModalExists(false);
    }, 300);
  }

  function closeModal2() {
    setIsSuccess(false);
  }
  function closePopup() {
    setisPopupVisible(false);
  }

  function filterTickets() {
    if (!tickets) {
      return [];
    }
    console.log([...tickets].filter((t) => t.count > 0));
    return [...tickets].filter((t) => t.count > 0);
  }

  return (
    <>
      <div className="container">
        <div className="cart">
          <div className="cart-1">
            <div className="img d-flex justify-content-center">
              <img
                src={
                  eventDetails?.event_banner
                    ? environment.gpcStorage + eventDetails.event_banner
                    : assets.sample_img
                }
                alt="event cover"
                loading="lazy"
              />
            </div>

            <div className="info">
              <h3 className="name">
                {eventDetails?.event_name || 'Not Available'}
              </h3>

              <p className="date">{formatDate(eventDetails?.event_datetime)}</p>

              <div className="info-1 mt-3 mb-3">
                <h5>Organizer</h5>

                <p>{eventDetails?.event_organizer?.name || 'Not Available'}</p>
              </div>

              <div className="info-1 mt-3 mb-3">
                <h5>Venue</h5>

                <p>{eventDetails?.venue || 'Not Available'}</p>
              </div>

              <div className="info-1 mt-3 mb-3">
                <h5 className="mb-3">Tickets</h5>

                <div className="tickets">
                  <div className="tickets-1">
                    {tickets ? (
                      <>
                        {tickets.map((t, i) => (
                          <>
                            {t.is_active && !t.is_delete && (
                              <div
                                key={i}
                                className="ticket row d-flex align-items-center mb-4"
                              >
                                <div className="ticket-1 col-6 col-sm-6 d-flex align-items-center">
                                  {t.ticket_name || ''}
                                  {/* <strong>
                                {'Rs'} {t.ticket_amount || '0.00'}
                                </strong> */}
                                </div>
                                {t?.is_sold_out ? (
                                  <div className="ticket-5 col-6 col-sm-3">
                                    Sold out
                                  </div>
                                ) : (
                                  <div className="ticket-2 col-6 col-sm-3">
                                    <div>
                                      <button
                                        onClick={() => removeQuantity(t.id)}
                                        disabled={t.count === 0}
                                      >
                                        <img
                                          src={assets.remove_icon}
                                          alt="remove ticket"
                                          loading="lazy"
                                        />
                                      </button>
                                    </div>

                                    <div>
                                      <span>{t.count || 0}</span>
                                    </div>

                                    <div>
                                      <button
                                        onClick={() => addQuantity(t.id)}
                                        disabled={
                                          parseInt(t.remaining_tickets) === 0 ||
                                          t?.is_sold_out
                                        }
                                      >
                                        <img
                                          src={assets.add_icon}
                                          alt="add ticket"
                                          loading="lazy"
                                        />
                                      </button>
                                    </div>
                                  </div>
                                )}

                                {t.show_remaining_tickets && !t?.is_sold_out ? (
                                  <div className="ticket-3 col-12 col-sm-3 text-sm-end">
                                    {parseInt(t.remaining_tickets) || 0}{' '}
                                    Remaining tickets
                                  </div>
                                ) : (
                                  ''
                                )}
                                {event_id !== 'QX5C118E404233F1032E0' && (
                                  <div className="ticket-1 col-12 col-sm-12 d-flex align-items-center">
                                    <p>{t.ticket_description || ''}</p>
                                  </div>
                                )}
                                <div className="ticket-1 col-6 col-sm-6 d-flex align-items-center">
                                  <strong>
                                    {eventDetails.tickets_currency}{' '}
                                    {t.ticket_amount || '0.00'}
                                  </strong>
                                </div>
                                {event_id === 'QX5C118E404233F1032E0' && (
                                  <div className="ticket-4 col-12 col-sm-12 d-flex align-items-center">
                                    <p>{t.ticket_description || ''}</p>
                                  </div>
                                )}
                              </div>
                            )}
                          </>
                        ))}
                      </>
                    ) : (
                      <p>No tickets available!</p>
                    )}
                  </div>

                  <div>
                    <hr />
                  </div>

                  <div className="total d-flex align-items-center justify-content-between mb-4">
                    <span>Total Amount</span>
                    <div className="d-flex align-items-center justify-content-end">
                      {discount !== 0 && (
                        <span className="discount">
                          {eventDetails?.tickets_currency}{' '}
                          {(discount + total).toFixed(2)}
                        </span>
                      )}
                      <span>
                        {eventDetails?.tickets_currency} {total.toFixed(2)}
                      </span>
                    </div>
                  </div>
                  {/* <div className="col-12 col-sm-12 d-flex flex-row align-items-center justify-content-between">
                      <div className="col-6 col-sm-6 d-flex flex-row align-items-start justify-content-between">
                        <div className=" col-12 col-sm-12">
                          <input
                            type="text"
                            className={`form-control`}
                            placeholder="Write your coupon code"
                            {...register('coupon', {
                              required: { value: false, message: 'Please write your coupon code' },
                            })}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <button className="primary-btn buy-btn" onClick={buy}>aeb
                        </div>
                      </div>
                      <div className="col-12 col-sm-3">
                        <button className="primary-btn buy-btn" onClick={buy}>
                          Buy Tickets
                        </button>
                      </div>
                    </div> */}
                  <div className="d-flex flex-column flex-sm-row align-items-center justify-content-between">
                    {eventDetails?.is_enable_coupon && !isRedeemOnce ? (
                      <div className="col-12 col-sm-8 pe-0 pe-sm-2 mb-3 d-flex align-items-start">
                        <input
                          type="text"
                          className={`form-control coupon-input`}
                          placeholder="Write your coupon code"
                          value={redeemCode}
                          onChange={handleCoupenCode}
                        />
                        <button
                          className="primary-btn3 buy-btn"
                          onClick={submitCoupon}
                        >
                          Redeem
                        </button>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div className="col-12 col-sm-4 mb-3 d-flex align-items-center justify-content-end">
                      <button className="primary-btn buy-btn" onClick={buy}>
                        Buy Tickets
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {event_id !== 'QX5C118E404233F1032E0' ? (
            <div className="cart-2 mt-5">
              <EventDetails content={eventDetails?.event_description} />
            </div>
          ) : (
            ''
          )}
        </div>
        {isPopupVisible && (
          <div className="confirmation-dialog">
            <div className="d-flex align-items-center justify-content-end">
              <button onClick={closePopup} className="close-btn-coperate">
                <img
                  src={assets.close_icon}
                  alt="close offcanvas icon"
                  loading="lazy"
                />
              </button>
            </div>
            <div className="confirmation-dialog-div d-flex align-items-center justify-content-center">
              <div className="topic">This is a private event 🔒</div>
              <div className="sub-topic">Validate your entry</div>
              <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center mt-4 pb-1">
                <input
                  type="text"
                  className={`form-control coupon-input`}
                  placeholder="Enter membership ID"
                  value={validateCode}
                  onChange={handleCodeValidate}
                />
                <button
                  className="primary-btn buy-btn"
                  onClick={verifyMembershipCode}
                >
                  Validate
                </button>
              </div>
            </div>
          </div>
        )}
        {isPopupVisible && <div className="confirmation-overlay"></div>}

        {isModalExists ? (
          isCoperate ? (
            <PayFormSpecific
              isOpen={isModalOpen}
              customFields={customFields}
              eventDetails={eventDetails}
              event_id={event_id}
              tickets={filterTickets()}
              coupenCode={shareRedeemCode}
              total={total}
              close={closeModal}
            />
          ) : (
            <PayForm
              isOpen={isModalOpen}
              customFields={customFields}
              eventDetails={eventDetails}
              event_id={event_id}
              tickets={filterTickets()}
              coupenCode={shareRedeemCode}
              total={total}
              close={closeModal}
            />
          )
        ) : null}

        {isSuccess ? <SuccessModal close={closeModal2} /> : null}
      </div>

      {isLoading ? (
        <div className="loading-div">
          <LoadingSpinner />
        </div>
      ) : null}
    </>
  );
}
