import React, { useRef, useState, useEffect } from 'react';
import { assets, environment } from '../data';

export default function BannerCarousel({ banners }) {
  let interval = useRef(null);
  const [index, setIndex] = useState(0);

  // Auto slide interval in milliseconds (adjust as needed)
  const autoSlideInterval = 2000;

  useEffect(() => {
    const currentIndex =
      parseInt(localStorage.getItem('currentIndex'), 10) || 0;
    setIndex(currentIndex);

    const autoSlideTimer = setTimeout(() => {
      let newIndex = currentIndex;

      if (newIndex >= banners.length - 1) {
        newIndex = 0;
      } else {
        newIndex += 1;
      }

      setIndex(newIndex);
      localStorage.setItem('currentIndex', newIndex.toString());
    }, autoSlideInterval);

    return () => clearTimeout(autoSlideTimer);
  }, [index, banners.length]);

  // Construct the background image URL
  const backgroundImageUrl = `${environment.gpcStorage}${banners[index]}`;

  return (
    <div className="banner-carousel">
      <div
        className="img"
        style={{
          backgroundImage: `url(${backgroundImageUrl})`,
        }}
      ></div>
      <img src={assets.group_icon} alt="" className="i5 d-none d-lg-block" />
    </div>
  );
}
