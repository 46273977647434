import React from 'react';

import { assets } from '../data';

export default function LeftOffCanvas({ children, isOpen, heading, close }) {
  function closeCanvas() {
    close();
  }

  // disable page scrolling
  if (isOpen) {
    document.body.classList.add('no-scroll');
    document.getElementById('left-off-canvas-wrapper')?.classList.add('open');
    document.getElementById('left-off-canvas')?.classList.add('open');
  } else {
    document.body.classList.remove('no-scroll');
    document
      .getElementById('left-off-canvas-wrapper')
      ?.classList.remove('open');
    document.getElementById('left-off-canvas')?.classList.remove('open');
  }

  return (
    <div className="left-off-canvas-wrapper" id="left-off-canvas-wrapper">
      <div className="left-off-canvas" id="left-off-canvas">
        <div className="header d-flex align-items-center justify-content-between mb-5">
          <h3>{heading}</h3>

          <button onClick={closeCanvas} className="close-btn">
            <img
              src={assets.close_icon}
              alt="close offcanvas icon"
              loading="lazy"
            />
          </button>
        </div>

        {children}
      </div>

      <div className="backdrop" onClick={closeCanvas}></div>
    </div>
  );
}
