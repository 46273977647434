// MetaUpdater.js;
// import React, { useEffect } from 'react';
// import { useMeta } from './MetaContext';

// const MetaUpdater = () => {
//   const { meta } = useMeta();

//   useEffect(() => {
//     document.title = meta.title;
//     const metaDescription = document.querySelector('meta[name="description"]');
//     if (metaDescription) {
//       metaDescription.setAttribute('content', meta.description);
//     } else {
//       const newMetaDescription = document.createElement('meta');
//       newMetaDescription.name = 'description';
//       newMetaDescription.content = meta.description;
//       document.head.appendChild(newMetaDescription);
//     }
//   }, [meta]);

//   return null;
// };

// export default MetaUpdater;

import React, { useEffect } from 'react';
import { useMeta } from './MetaContext';
import { useLocation } from 'react-router-dom';

const MetaUpdater = () => {
  const { meta } = useMeta();
  const location = useLocation();

  useEffect(() => {
    document.title = meta.title;

    const metaTags = {
      description: document.querySelector('meta[name="description"]'),
      ogTitle: document.querySelector('meta[property="og:title"]'),
      ogDescription: document.querySelector('meta[property="og:description"]'),
      ogImage: document.querySelector('meta[property="og:image"]'),
      ogUrl: document.querySelector('meta[property="og:url"]'),
      twitterTitle: document.querySelector('meta[name="twitter:title"]'),
      twitterDescription: document.querySelector(
        'meta[name="twitter:description"]',
      ),
      twitterImage: document.querySelector('meta[name="twitter:image"]'),
      twitterUrl: document.querySelector('meta[name="twitter:url"]'),
    };

    if (metaTags.description)
      metaTags.description.setAttribute('content', meta.description);
    if (metaTags.ogTitle) metaTags.ogTitle.setAttribute('content', meta.title);
    if (metaTags.ogDescription)
      metaTags.ogDescription.setAttribute('content', meta.description);
    if (metaTags.ogImage) metaTags.ogImage.setAttribute('content', meta.image);
    if (metaTags.ogUrl) metaTags.ogUrl.setAttribute('content', meta.url);
    if (metaTags.twitterTitle)
      metaTags.twitterTitle.setAttribute('content', meta.title);
    if (metaTags.twitterDescription)
      metaTags.twitterDescription.setAttribute('content', meta.description);
    if (metaTags.twitterImage)
      metaTags.twitterImage.setAttribute('content', meta.image);
    if (metaTags.twitterUrl)
      metaTags.twitterUrl.setAttribute('content', meta.url);
  }, [meta, location]);

  return null;
};

export default MetaUpdater;

// MetaUpdater.js
// import { useEffect } from 'react';
// import { useMeta } from './MetaContext';
// import { useLocation, useParams } from 'react-router-dom';

// const MetaUpdater = () => {
//   const { meta, setMeta } = useMeta();
//   const location = useLocation();
//   const params = useParams();

//   useEffect(() => {
//     const updateMeta = () => {
//       let updatedMeta = {};

//       if (location.pathname.includes('/coperate')) {
//         updatedMeta = {
//           title: `Corporate Event ${params.event_id}`,
//           description: `Corporate event for assignee ${params.assignee_id}`,
//           image: 'corporate-event-image-url',
//         };
//       } else if (params.event_id) {
//         updatedMeta = {
//           title: `Event ${params.event_id}`,
//           description: `Event details for ${params.event_id}`,
//           image: `event-image-url/${params.event_id}`,
//         };
//       } else {
//         updatedMeta = {
//           title: 'Default Title',
//           description: 'Default Description',
//           image: 'default-image-url',
//         };
//       }

//       if (
//         meta.title !== updatedMeta.title ||
//         meta.description !== updatedMeta.description ||
//         meta.image !== updatedMeta.image
//       ) {
//         setMeta(updatedMeta);
//       }

//       document.title = updatedMeta.title;
//       document
//         .querySelector('meta[name="description"]')
//         .setAttribute('content', updatedMeta.description);
//       document
//         .querySelector('meta[property="og:title"]')
//         .setAttribute('content', updatedMeta.title);
//       document
//         .querySelector('meta[property="og:description"]')
//         .setAttribute('content', updatedMeta.description);
//       document
//         .querySelector('meta[property="og:image"]')
//         .setAttribute('content', updatedMeta.image);
//       document
//         .querySelector('meta[name="twitter:title"]')
//         .setAttribute('content', updatedMeta.title);
//       document
//         .querySelector('meta[name="twitter:description"]')
//         .setAttribute('content', updatedMeta.description);
//       document
//         .querySelector('meta[name="twitter:image"]')
//         .setAttribute('content', updatedMeta.image);
//     };

//     updateMeta();
//   }, [location.pathname, params.event_id, params.assignee_id, meta, setMeta]);

//   return null;
// };

// export default MetaUpdater;
