import React from 'react';

import { assets } from '../data';

export default function NotFound() {
  return (
    <div className="container">
      <div className="not-found d-flex flex-column align-items-center justify-content-center">
        <img src={assets.not_found_img} alt="page not found" loading="lazy" />

        <h3>Page Not Found</h3>
      </div>
    </div>
  );
}
