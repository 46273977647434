export const environment = {
  // BASE_URL2: 'https://merchant-api-staging.onepay.lk/api',
  BASE_URL2:
    'https://oneticket-backend-uat-dot-digitallabs-438614.el.r.appspot.com/api/v3',
  // BASE_URL2:
  //   ' https://oneticket-backend-live-dot-digitallabs-438614.el.r.appspot.com/api/v3',

  BASE_URL3: 'https://merchant-api-live-v2.onepay.lk/api',
  aws: 'https://storage.googleapis.com',
  gpcStorage: 'https://storage.googleapis.com/oneticket/',
};

//// assets ////
import close_icon from './assets/svg/close.svg';
import close_icon2 from './assets/img/close-fill.png';
// cart
import sample_img from './assets/svg/sample.svg';
import add_icon from './assets/svg/add.svg';
import remove_icon from './assets/svg/remove.svg';

// events
import search_icon from './assets/svg/search.svg';
import group_icon from './assets/svg/group.svg';
import polygon_icon from './assets/svg/polygon.svg';
import polygon_filled_icon from './assets/svg/polygon_filled.svg';
import location from './assets/img/location.png';
import clock from './assets/img/clock.png';

// not found
import not_found_img from './assets/svg/not_found.svg';

// pay form
import logos_img from './assets/img/logos.png';
import onepay_pg_logo from './assets/img/onepay_pg_logo.png';

// header
import logo from './assets/svg/oneticket_logo.svg';

// footer
import facebook_icon from './assets/svg/facebook.svg';
import instagram_icon from './assets/svg/instagram.svg';
import twitter_icon from './assets/svg/twitter.svg';
import linkedIn_icon from './assets/svg/linkedin.svg';
import close_new from './assets/img/close-circle-line.png';
import popup_img from './assets/img/Group 32993.png';
import popup_people from './assets/img/image 1.png';
import close_popup from './assets/img/close-circle-line (1).png';
import whats_app from './assets/img/image 2.png';
import sold_out from './assets/img/sold-out-png-19969.png';

// success modal
import tick_icon from './assets/svg/tick.svg';
import payform_img from './assets/img/One Pay Banner 800 60.jpg';

export const assets = {
  close_icon,
  close_icon2,
  // cart
  sample_img,
  add_icon,
  remove_icon,

  // events
  search_icon,
  group_icon,
  polygon_icon,
  polygon_filled_icon,
  location,
  clock,

  // not found
  not_found_img,

  // pay form
  logos_img,
  onepay_pg_logo,

  // header
  logo,

  // footer
  facebook_icon,
  instagram_icon,
  twitter_icon,
  linkedIn_icon,

  // success modal
  tick_icon,
  close_new,
  popup_img,
  popup_people,
  close_popup,
  whats_app,
  sold_out,
  payform_img,
};
////
